* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.fileUpload {
  background-color: #6366f1;
  color: white;
  padding: 0.5rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  width: 200px;
}
@media print {
  .page-break-directory {
    /* margin-top: 1rem; */
    /* display: block; */
    page-break-before: auto;
    page-break-inside: avoid;
  }
  .print-header {
    /* position: fixed;
    top: 0;
    left: auto;
    right: auto; */
    /* height: 30px; Adjust the height as needed */
    color: #000;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
    text-align: center;
    width: 100%;
  }
}
.custom-header-table {
}
.custom-header-table > td {
  margin-bottom: 10mm;
  text-align: center;
  height: 25mm;
}
