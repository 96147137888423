.textInput{
    border: 1px solid #000000;
    width: 300px;
    padding-right: 5px;
    padding-top: 10px;
    margin-top: 2px;
  }
.inputText{
        width: 300px;
        border: 1px solid #000000;
        border-radius: 0px;
        text-align: left;
        margin-left: 5px;
}