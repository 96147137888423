.topTemplate {
  background-color: black;
  height: 30vh;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.smallContainer {
  height: 120px;
  width: 200px;
  border: 3px solid #ebeef7;
  border-radius: 5px;
  margin: 30px;
}
.searchBar{
  padding: 20px;
  background-color: #fff;
  margin-top: -50px;
  display: flex;
  justify-content: space-evenly;
  box-shadow: 0px 0px 32px rgba(0, 34, 51, 0.08);
  border-radius: 10px;
  width: 1357px;
 margin-left: auto;
 margin-right: auto;
}
.smallIconsContainer {
  font-size: 25px;
  margin-top: 15px;
}

/* DataViewDemo.css */

.dataview-demo .p-dropdown {
  width: 14rem;
  font-weight: normal;
}

.dataview-demo .product-name {
  font-size: 1.1rem;
  font-weight: 600;
  margin:5px;
}
.dataview-demo .product-text {
  margin: 5px;
}

.dataview-demo .product-description {
  margin: 0 0 1rem 0;
}

.dataview-demo .product-category-icon {
  vertical-align: middle;
  margin-right: 0.5rem;
}

.dataview-demo .product-category {
  font-weight: 600;
  vertical-align: middle;
}

.dataview-demo .product-grid-item {
  margin: 0.5em;
  border-radius: 10px;
  border: 1px solid var(--surface-border);
}

.dataview-demo .product-grid-item .product-grid-item-top,
.dataview-demo .product-grid-item .product-grid-item-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dataview-demo .product-grid-item img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.dataview-demo .product-grid-item .product-grid-item-content {
  text-align: center;
}

.dataview-demo .product-grid-item .product-price {
  font-size: 1.5rem;
  font-weight: 600;
}

@media screen and (max-width: 576px) {
  .dataview-demo .product-list-item {
    flex-direction: column;
    align-items: center;
  }

  .dataview-demo .product-list-item img {
    width: 75%;
    margin: 2rem 0;
  }

  .dataview-demo .product-list-item .product-list-detail {
    text-align: center;
  }

  .dataview-demo .product-list-item .product-price {
    align-self: center;
  }

  .dataview-demo .product-list-item .product-list-action {
    display: flex;
    flex-direction: column;
  }

  .dataview-demo .product-list-item .product-list-action {
    margin-top: 2rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
